import React from "react"
import { IconContext } from "react-icons"

import {
  BlurbElement,
  BlurbIconElement,
  BlurbHeadingElement,
  BlurbTextElement,
  BlurbHeaderElement,
} from "../elements/BlurbElements"

export const Blurb = (props) => {
  return <BlurbElement {...props}>{props.children}</BlurbElement>
}

export const BlurbIcon = (props) => {
  return (
    <IconContext.Provider value={{ style: { fontSize: "50px", color: props.color } }}>
      <BlurbIconElement {...props}>{props.children}</BlurbIconElement>
    </IconContext.Provider>
  )
}

export const BlurbHeading = (props) => {
  return <BlurbHeadingElement {...props}>{props.children}</BlurbHeadingElement>
}

export const BlurbText = (props) => {
  return <BlurbTextElement {...props}>{props.children}</BlurbTextElement>
}

export const BlurbHeader = (props) => {
  return <BlurbHeaderElement {...props}>{props.children}</BlurbHeaderElement>
}
