import React from "react"
import { graphql, Link } from "gatsby"
import { getImage, getSrc, getSrcSet } from "gatsby-plugin-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { PageLayout, TwoOptions } from "../components/PageLayout"
import {
  HeroGrid,
  HeroSection,
  HeroHeadline,
  HeroSubHead,
  // HeroButton,
} from "../components/HeroSection"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
  OneColumnSection,
  OverlapRow,
  ThreeColumn,
  ThreeColumnOne,
  ThreeColumnTwo,
  ThreeColumnThree,
  ColumnInternal,
  ColumnInternalTop,
  ColumnInternalBottom,
} from "../components/SectionBlocks"

import { Responsive2ColumnContainer, Responsive2ColumnItem } from "../components/Responsive2Column"

import { StyledIcon } from "../components/StyledIcon"
import { ArrowCircleRightIcon } from "@heroicons/react/solid"

import {
  Blurb,
  BlurbIcon,
  BlurbHeading,
  // BlurbBody,
  BlurbHeader,
  BlurbText,
} from "../components/Blurb"
import { ButtonPrimary, ButtonSecondary } from "../components/Buttons"

import {
  BulletedIconWrapper,
  BulletedIconLeft,
  BulletedIconRight,
  // BulletedIconIcon,
} from "../components/BulletedIcon"

import {
  Testimonial3ColumnWrapper,
  TestimonialWrapper,
  TestimonialImage,
  TestimonialName,
  TestimonialTitle,
  TestimonialMessage,
} from "../components/Testimonial"

import { GoChecklist, GoGraph } from "react-icons/go"
import { FiUserCheck, FiTarget } from "react-icons/fi"
import { GiBiceps } from "react-icons/gi"
import { FaWeight, FaFootballBall, FaUserTie, FaCheck, FaComments } from "react-icons/fa"
import theme from "../themes/theme"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title="Look great, get stronger, play longer"
          ogImage={data?.socialImage?.childImageSharp?.fixed.src}
          description="Coaching professionals to look great, get stronger & play harder"
          url={location.origin + location.pathname}
        />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the directory you specified
          for the "gatsby-source-filesystem" plugin in gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <>
      <SEO
        title="Look great, get stronger, play longer"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
      />
      <PageLayout>
        {/* <HeroGrid picUrl={data?.heroImageMitchAlex?.childImageSharp?.fixed} bgX="60%" bgY="0%"> */}
        <HeroGrid
          picUrl={data?.heroImageMitchAlex?.childImageSharp?.fluid}
          bgX="0%"
          mobileColor={theme.colors.background}
          style={{ marginLeft: "1rem", marginRight: "1rem" }}>
          <HeroSection className="ml-2">
            <HeroHeadline>
              <h1>
                We help busy professionals to
                <span style={{ color: theme.colors.primaryBlueDarker }}> look great</span>, get
                stronger, and play longer
              </h1>
            </HeroHeadline>
            <HeroSubHead>
              <p>
                Train with our experienced strength coaches as they tailor proven systems to help
                you increase your strength and conditioning so you can look and feel great, play
                longer, and enjoy life.
              </p>
              <p
                style={{
                  fontFamily: theme.fonts.header1,
                  color: theme.colors.primaryOrangeNormal,
                  fontWeight: 600,
                  fontSize: theme.spacings.small,
                  // fontSize: "1.2rem",
                }}>
                I want to ...
              </p>
            </HeroSubHead>
            {/* <TwoOptions>
              <Link
                to="/train-at-gym"
                style={{
                  fontFamily: theme.fonts.header1,
                  textDecoration: "none",
                  fontSize: "1.5rem",
                  color: theme.colors.black,
                }}>
                <div className="flex flex-col items-center justify-start text-center">
                  <img
                    src={data.atGymImage.publicURL}
                    alt="Train at the gym"
                    width="65%"
                    height="65%"
                    style={{
                      padding: "10px",
                    }}
                  />
                  <strong>GYM</strong>
                </div>
              </Link>
              <div>
                <Link
                  to="/train-at-home"
                  style={{
                    fontFamily: theme.fonts.header1,
                    // paddingLeft: "20px",
                    textDecoration: "none",
                    fontSize: "1.5rem",
                    color: "#333333",
                  }}>
                  <div className="flex flex-col items-center justify-start ">
                    <img
                      src={data.atHomeImage.publicURL}
                      alt="Train at home"
                      width="65%"
                      height="65%"
                      style={{ padding: "10px" }}
                    />
                    <strong>HOME</strong>
                  </div>
                </Link>
              </div>
            </TwoOptions> */}
            <TwoOptions>
              <div className="w-full md:w-1/2">
                <Link to="/free-assessment?g=wl">
                  <div
                    className="flex flex-row pl-4 md:items-center md:justify-center w-full font-sans text-2xl text-center shadow-xl rounded-xl border-2 border-primaryBlueNormal bg-primaryBlueNormal text-white hover:text-primaryBlueNormal hover:bg-white hover:border-2
              hover:border-primaryBlueNormal uppercase py-2 font-semibold">
                    Lose weight
                    <ArrowCircleRightIcon className="ml-1 h-7 w-7" />
                  </div>
                </Link>
              </div>
              <div className="w-full md:w-1/2">
                <Link to="/free-assessment?g=sg">
                  <div
                    className="flex flex-row pl-4 md:items-center md:justify-center w-full font-sans text-2xl text-center shadow-xl rounded-xl border-2 border-primaryOrangeNormal bg-primaryOrangeNormal text-white hover:text-primaryOrangeNormal hover:bg-white hover:border-2
              hover:border-primaryOrangeNormal uppercase py-2 font-semibold">
                    Get stronger
                    <ArrowCircleRightIcon className="ml-1 h-7 w-7" />
                  </div>
                </Link>
              </div>
            </TwoOptions>
          </HeroSection>
        </HeroGrid>
        {/* <a name="getstronger"> */}
        <div id="_getstronger">&nbsp;</div>
        <div
          style={{
            marginLeft: "50px",
            marginRight: "50px",
            textAlign: "center",
          }}>
          <HeroHeadline>
            <h1>
              Get <i>fitter</i> and <strong>stronger,</strong> faster!
            </h1>
          </HeroHeadline>
          <p>
            Whether you have never stepped in a gym or lifted a barbell, or for those advanced
            lifters looking to get to the next level.
          </p>
        </div>

        <div
          name="HowWeDoIt"
          className="flex flex-col md:flex-row mx-auto px-4 md:px-14 justify-center max-w-fit">
          <div name="HowCol1" className="flex flex-col">
            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoChecklist />
                </BlurbIcon>
                <BlurbHeading>Tailored Program</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Work one on one with your coach to set personal goals and follow a proven program to
                get the results you want. Using a science based approach for success.
              </BlurbText>
            </Blurb>
            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <FiUserCheck />
                </BlurbIcon>
                <BlurbHeading>Body Recomposition</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                <span>
                  It <strong>is</strong> possible to lose fat weight and gain muscle at the same
                  time. Get stronger and trim so you can lift that big number and look great in that
                  European cut shirt.
                </span>
              </BlurbText>
            </Blurb>
          </div>
          <div name="HowCol2" className="flex flex-col">
            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoGraph />
                </BlurbIcon>
                <BlurbHeading>Track Your Progress</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                What you measure you can improve. Our coaches provide access to Trainerize for
                customised workout and results tracking. Watch your progress as you improve over
                time.
              </BlurbText>
            </Blurb>
            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GiBiceps />
                </BlurbIcon>
                <BlurbHeading>Get Stronger</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Carry yourself with a new found confidence and know that you can open that jar, move
                that couch, and lift all your kids at once. Take pride in your lifts and progress.
              </BlurbText>
            </Blurb>
          </div>
          <div name="HowCol3" className="flex flex-col">
            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <FaWeight />
                </BlurbIcon>
                <BlurbHeading>Lose Weight</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Cut the fat and expose that powerful body underneath. Be confident at the beach, in
                the pool, on the field, playing with your kids, and with your partner.
              </BlurbText>
            </Blurb>
            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <FaFootballBall />
                </BlurbIcon>
                <BlurbHeading>Play Longer</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Benefit from your strength training to increase your endurance and lung capacity.
                Run that marathon or play the entire game - and keep your breath.
              </BlurbText>
            </Blurb>
          </div>
        </div>

        {/* <RowSection rowMargin="15%" style={{ marginTop: "50px" }}> */}
        <RowSection rowMargin="15%">
          <OneColumnSection>
            <HeroHeadline>
              <h1 style={{ textAlign: "center" }}>
                Get Started in{" "}
                <span style={{ color: theme.colors.primaryBlueNormal }}>Three Easy Steps</span> and
                Begin Achieving Your Goals{" "}
                <span style={{ color: theme.colors.primaryOrangeNormal }}>Today</span>
              </h1>
            </HeroHeadline>
          </OneColumnSection>
        </RowSection>
        <RowSection rowMargin="5%" style={{ justifyContent: "center" }}>
          <ThreeColumn>
            <ThreeColumnOne
              style={{
                backgroundColor: theme.colors.primaryLightGray,
                padding: "10px",
                borderRadius: "10px",
              }}>
              <ColumnInternal>
                <ColumnInternalTop>
                  {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}> */}
                  <img
                    src={data.step1.publicURL}
                    alt="Step 1"
                    width="100%"
                    height="100%"
                    // width="100%"
                    // height="100%"
                    style={{ padding: "10px" }}
                  />
                  {/* </div> */}
                </ColumnInternalTop>
                <ColumnInternalBottom>
                  <div style={{ textAlign: "center" }}>
                    <HeroHeadline>
                      <h2 style={{ color: theme.colors.primaryOrangeNormal }}>Step 1</h2>
                    </HeroHeadline>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ padding: "5px" }}>
                      <FaCheck
                        color={theme.colors.primaryBlueNormal}
                        fontSize={theme.spacings.medium}
                      />
                    </div>
                    <div style={{ padding: "5px" }}>
                      Book a Free Phone Assessment with one of our senior coaches
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ padding: "5px" }}>
                      <FaCheck
                        color={theme.colors.primaryBlueNormal}
                        fontSize={theme.spacings.medium}
                      />
                    </div>
                    <div style={{ padding: "5px" }}>
                      Share your goals and why they are important to you
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ padding: "5px" }}>
                      <FaCheck
                        color={theme.colors.primaryBlueNormal}
                        fontSize={theme.spacings.medium}
                      />
                    </div>
                    <div style={{ padding: "5px" }}>
                      Schedule a time for an in person training introduction
                    </div>
                  </div>
                </ColumnInternalBottom>
              </ColumnInternal>
            </ThreeColumnOne>
            <ThreeColumnTwo
              style={{
                backgroundColor: theme.colors.primaryLightGray,
                padding: "10px",
                borderRadius: "10px",
              }}>
              <ColumnInternal>
                <ColumnInternalTop>
                  {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}> */}
                  <img
                    src={data?.step2.publicURL}
                    alt="Step 2"
                    width="100%"
                    height="100%"
                    // width="300px"
                    // height="300px"
                    style={{ padding: "10px" }}
                  />
                  {/* </div> */}
                </ColumnInternalTop>
                <ColumnInternalBottom>
                  <div style={{ textAlign: "center" }}>
                    <HeroHeadline>
                      <h2 style={{ color: theme.colors.primaryOrangeNormal }}>Step 2</h2>
                    </HeroHeadline>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ padding: "5px" }}>
                      <FaCheck
                        color={theme.colors.primaryBlueNormal}
                        fontSize={theme.spacings.medium}
                      />
                    </div>
                    <div style={{ padding: "5px" }}>Share your goals with your coach</div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ padding: "5px" }}>
                      <FaCheck
                        color={theme.colors.primaryBlueNormal}
                        fontSize={theme.spacings.medium}
                      />
                    </div>
                    <div style={{ padding: "5px" }}>
                      Agree on a game plan based on a proven system
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ padding: "5px" }}>
                      <FaCheck
                        color={theme.colors.primaryBlueNormal}
                        fontSize={theme.spacings.medium}
                      />
                    </div>
                    <div style={{ padding: "5px" }}>
                      Set yourself up for success with the tools you need to track your progress
                    </div>
                  </div>
                </ColumnInternalBottom>
              </ColumnInternal>
            </ThreeColumnTwo>
            <ThreeColumnThree
              style={{
                backgroundColor: theme.colors.primaryLightGray,
                padding: "10px",
                borderRadius: "10px",
              }}>
              <ColumnInternal>
                <ColumnInternalTop>
                  {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}> */}
                  <img
                    src={data.step3.publicURL}
                    alt="Step 3"
                    width="100%"
                    height="100%"
                    // width="100%"
                    // height="100%"
                    style={{ padding: "10px" }}
                  />
                  {/* </div> */}
                </ColumnInternalTop>
                <ColumnInternalBottom>
                  <div style={{ textAlign: "center" }}>
                    <HeroHeadline>
                      <h2 style={{ color: theme.colors.primaryOrangeNormal }}>Step 3</h2>
                    </HeroHeadline>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ padding: "5px" }}>
                      <FaCheck
                        color={theme.colors.primaryBlueNormal}
                        fontSize={theme.spacings.medium}
                      />
                    </div>
                    <div style={{ padding: "5px" }}>
                      Complete initial measurements and fitness test
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ padding: "5px" }}>
                      <FaCheck
                        color={theme.colors.primaryBlueNormal}
                        fontSize={theme.spacings.medium}
                      />
                    </div>
                    <div style={{ padding: "5px" }}>Start training with your experienced coach</div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ padding: "5px" }}>
                      <FaCheck
                        color={theme.colors.primaryBlueNormal}
                        fontSize={theme.spacings.medium}
                      />
                    </div>
                    <div style={{ padding: "5px" }}>
                      Enjoy the results as you achieve your goals.
                    </div>
                  </div>
                </ColumnInternalBottom>
              </ColumnInternal>
            </ThreeColumnThree>
          </ThreeColumn>
        </RowSection>
        <div
          style={{
            marginLeft: "50px",
            marginRight: "50px",
            marginBottom: "50px",
            textAlign: "center",
            // paddingTop: "30px",
          }}>
          <h2>
            Want to <strong>feel</strong> strong and <i>move</i> better?
          </h2>
          <ButtonPrimary
            to="/free-assessment"
            style={{ textAlign: "center", alignItems: "center", justifyContent: "center" }}>
            Get Your Free Assessment Today!
          </ButtonPrimary>
        </div>
        {/* </a> */}
        <div id="_whyus">&nbsp;</div>
        <HeroGrid
          picUrl={data?.deadliftImage?.childImageSharp?.fixed}
          bgX="60%"
          bgY="0%"
          mobileColor="#000000">
          <HeroSection style={{ marginBottom: "60px", marginLeft: "1rem", marginRight: "1rem" }}>
            <HeroHeadline>
              <h1 style={{ color: theme.colors.primaryBlueNormal }}>Why Us</h1>
              <h2 style={{ color: theme.colors.primaryOrangeNormal }}>
                We aren't your average trainers
              </h2>
            </HeroHeadline>
            <HeroSubHead>
              <p style={{ color: theme.colors.white }}>
                We specialise in helping a very specific type of person
              </p>
              <p style={{ color: theme.colors.white }}>
                <strong>The Liftology Project</strong> is your <i>ideal coaching team</i> if you are
                a professional working long hours, you've probably got a young family, and used to
                play sport but now rarely find the time.
              </p>

              <BulletedIconWrapper>
                <BulletedIconLeft color={theme.colors.primaryBlueNormal}>
                  <FaUserTie />
                </BulletedIconLeft>
                <BulletedIconRight>
                  <h3 style={{ marginTop: "0px", marginBottom: "16px", color: theme.colors.white }}>
                    Professional
                  </h3>
                  <p style={{ color: theme.colors.dark3 }}>
                    You probably work in an office, often with regular travel and long hours.
                  </p>
                </BulletedIconRight>
              </BulletedIconWrapper>
              <BulletedIconWrapper>
                <BulletedIconLeft color={theme.colors.primaryPinkNormal}>
                  <FiTarget />
                </BulletedIconLeft>
                <BulletedIconRight>
                  <h3 style={{ marginTop: "0px", marginBottom: "16px", color: theme.colors.white }}>
                    Have a goal
                  </h3>
                  <p style={{ color: theme.colors.dark3 }}>
                    You know what you want, but aren't really sure on how to achieve it.
                  </p>
                </BulletedIconRight>
              </BulletedIconWrapper>
              <BulletedIconWrapper>
                <BulletedIconLeft color={theme.colors.primaryOrangeNormal}>
                  <FaFootballBall />
                </BulletedIconLeft>
                <BulletedIconRight>
                  <h3 style={{ marginTop: "0px", marginBottom: "16px", color: theme.colors.white }}>
                    Sporting history
                  </h3>
                  <p style={{ color: theme.colors.dark3 }}>
                    Whether at school or university, you played competitive sport and enjoyed it -
                    but in recent years work and life have kept you away.
                  </p>
                </BulletedIconRight>
              </BulletedIconWrapper>
              <ButtonSecondary
                to="/free-assessment"
                style={{ textAlign: "center", boxShadow: "none" }}>
                {/* <p stye={{ fontSize: theme.spacings.xSmall }}> Speak With A Coach Today</p> */}
                Speak With A Coach Today
              </ButtonSecondary>
            </HeroSubHead>
          </HeroSection>
        </HeroGrid>
        <div id="_testimonials">&nbsp;</div>
        <div
          style={{
            backgroundColor: theme.colors.background,
            paddingBottom: "40px",
            paddingTop: "40px",
            marginBottom: "10px",
            marginLeft: "1rem",
            marginRight: "1rem",
          }}>
          <center>
            <StyledIcon size="120px" color={theme.colors.primaryBlueNormal}>
              <FaComments />
            </StyledIcon>

            <h1
              style={{
                marginLeft: "15px",
                marginRight: "15px",
                lineHeight: theme.spacings.xLarge,
              }}>
              What <span style={{ color: theme.colors.primaryBlueNormal }}>real people</span> say
              about Liftology
            </h1>
            <p>Don't take our word for it, hear directly from some of our clients.</p>
          </center>
          {/* <Testimonial3ColumnWrapper> */}
          <div className="flex flex-wrap flex-row justify-center">
            <TestimonialWrapper>
              <TestimonialImage
                width="120px"
                height="120px"
                alt="Andrew Hatfield"
                src={data?.headshotAndrewHatfield?.childImageSharp?.gatsbyImageData}
              />
              {/* <Img fixed={data?.headshotAndrewHatfield?.childImageSharp.fixed} alt="Andrew" /> */}
              <TestimonialName>Andrew Hatfield</TestimonialName>
              <TestimonialTitle>Marketing</TestimonialTitle>
              <TestimonialMessage>
                After many years of running, and playing tennis and soccer, I ended up injuring my
                back. Sneezing was often dangerous enough to put me out of action for days. Working
                with Mitch for just over a year, I now no longer fear sneezing and can easily keep
                up with my kids, move furniture, and lift more than my bodyweight!
              </TestimonialMessage>
            </TestimonialWrapper>
            <TestimonialWrapper>
              <TestimonialImage
                width="120px"
                height="120px"
                alt="Byron Paine"
                src={data?.headshotByronPaine?.childImageSharp?.gatsbyImageData}
              />

              <TestimonialName>Byron Paine</TestimonialName>
              <TestimonialTitle>Retail</TestimonialTitle>
              <TestimonialMessage>
                Mitch has helped me realise progress comes in all shapes and forms, and that it’s
                not going to be an easy task hut that it can be done. Mitch has shown incredible
                dedication to myself and his other clients which drives our thirst for strength even
                more.
              </TestimonialMessage>
            </TestimonialWrapper>
            <TestimonialWrapper>
              <TestimonialImage
                width="120px"
                height="120px"
                alt="Craig MacDonald"
                src={data?.headshotCraigMacDonald?.childImageSharp?.gatsbyImageData}
              />

              <TestimonialName>Craig MacDonald</TestimonialName>
              <TestimonialTitle>Property</TestimonialTitle>
              <TestimonialMessage>
                Mitch isn't your bog standard trainer, and I can say that having trained with a
                couple before him. Mitch is your coach. Not only does he know his craft well, he is
                always learning and staying up to date. Attentive and focused, picking up on small
                but important incremental improvements.
              </TestimonialMessage>
            </TestimonialWrapper>
            {/* </Testimonial3ColumnWrapper> */}
            {/* second set */}
            {/* <Testimonial3ColumnWrapper> */}
            <TestimonialWrapper>
              <TestimonialImage
                width="120px"
                height="120px"
                alt="Michel Hanson"
                src={data?.headshotMichaelHanson?.childImageSharp?.gatsbyImageData}
              />
              <TestimonialName>Michael Hanson</TestimonialName>
              <TestimonialTitle>Media</TestimonialTitle>
              <TestimonialMessage>
                Absolutely an amazing PT, listen to him! My stress levels dropped and my confidence
                and overall health went through the roof.. I would recommend him to anyone! Get in
                with him before he is fully booked!
              </TestimonialMessage>
            </TestimonialWrapper>
            <TestimonialWrapper>
              <TestimonialImage
                width="120px"
                height="120px"
                alt="Dallas Allen"
                src={data?.headshotDallasAllen?.childImageSharp?.gatsbyImageData}
              />

              <TestimonialName>Dallas Allen</TestimonialName>
              <TestimonialTitle>Beauty</TestimonialTitle>
              <TestimonialMessage>
                Excellent one on one PT experience, fully understands the body and how to give you
                the best and right experience. Takes the time to really look at your form and
                correct it for the right work outs. My results have been amazing and my confidence
                working out on my own even higher now.
              </TestimonialMessage>
            </TestimonialWrapper>
            <TestimonialWrapper>
              <TestimonialImage
                width="120px"
                height="120px"
                alt="Junyi Zhou"
                src={data?.headshotJunyiZhou?.childImageSharp?.gatsbyImageData}
              />

              <TestimonialName>Junyi Zhou</TestimonialName>
              <TestimonialTitle>Construction</TestimonialTitle>
              <TestimonialMessage>
                Mitch is an excellent PT who has a great sense of humor! After training with him for
                over a year, I can see a big change in my energy levels and body. If you are skinny
                and want more curves, look no further;)
              </TestimonialMessage>
            </TestimonialWrapper>
            {/* </Testimonial3ColumnWrapper> */}
          </div>
          <ButtonPrimary
            to="/free-assessment"
            style={{
              textAlign: "center",
              marginLeft: "20px",
              marginRight: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}>
            {/* <p stye={{ fontSize: theme.spacings.xSmall }}> Speak With A Coach Today</p> */}
            Start Today And Get Similar Results
          </ButtonPrimary>
        </div>
      </PageLayout>

      <>
        {/* <Layout location={location} title={siteTitle}>
          <SEO title="All posts" />
          <Bio />
          <ol style={{ listStyle: `none` }}>
            {posts.map((post) => {
              const title = post.frontmatter.title || post.fields.slug

              return (
                <li key={post.fields.slug}>
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article">
                    <header>
                      <h2>
                        <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h2>
                      <small>{post.frontmatter.date}</small>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </section>
                  </article>
                </li>
              )
            })}
          </ol>
        </Layout> */}
      </>
    </>
  )
}

export default BlogIndex

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt
//         fields {
//           slug
//         }
//         frontmatter {
//           date(formatString: "MMMM DD, YYYY")
//           title
//           description
//         }
//       }
//     }
//     heroImage: file(absolutePath: { regex: "/mitch-squat-light-1920x874.jpg/" }) {
//       childImageSharp {
//         fixed(width: 1920, height: 874, quality: 95) {
//           ...GatsbyImageSharpFixed_withWebp
//         }
//       }
//     }
//     handstandImage: file(absolutePath: { regex: "/mitch-handstand-dark-1920x874.jpg/" }) {
//       childImageSharp {
//         fixed(width: 1200, height: 874, quality: 95) {
//           ...GatsbyImageSharpFixed_withWebp
//         }
//         fluid(maxWidth: 1200) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//     atHomeImage: file(absolutePath: { regex: "/Home.svg/" }) {
//       publicURL
//     }
//     atGymImage: file(absolutePath: { regex: "/Dumbbell.svg/" }) {
//       publicURL
//     }

//     headshotAndrewHatfield: file(absolutePath: { regex: "/andrewhatfield-170x170.jpg/" }) {
//       childImageSharp {
//         fixed(width: 120, height: 120, quality: 100) {
//           ...GatsbyImageSharpFixed_withWebp
//         }
//       }
//     }

//     headshotByronPaine: file(absolutePath: { regex: "/byron-170x170.jpg/" }) {
//       childImageSharp {
//         fixed(width: 120, height: 120, quality: 100) {
//           ...GatsbyImageSharpFixed_withWebp
//         }
//       }
//     }

//     headshotCraigMacDonald: file(absolutePath: { regex: "/craig-macdonald-170x170.jpg/" }) {
//       childImageSharp {
//         fixed(width: 120, height: 120, quality: 100) {
//           ...GatsbyImageSharpFixed_withWebp
//         }
//       }
//     }

//     headshotMitchJobson: file(
//       absolutePath: { regex: "/mitch-standing-arms-crossed-blue-20200506_152253-2-778x778.jpg/" }
//     ) {
//       childImageSharp {
//         fixed(width: 778, height: 778, quality: 95) {
//           ...GatsbyImageSharpFixed_withWebp
//         }
//       }
//     }

//     socialImage: file(absolutePath: { regex: "/LiftologyProject-Banner-Squat-1200x628.jpg/" }) {
//       childImageSharp {
//         fixed(width: 1200, height: 628, quality: 95) {
//           ...GatsbyImageSharpFixed_withWebp
//         }
//       }
//     }
//   }
// `

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }

    step1: file(absolutePath: { regex: "/pick-date.svg/" }) {
      publicURL
    }

    step2: file(absolutePath: { regex: "/conversation.svg/" }) {
      publicURL
    }

    step3: file(absolutePath: { regex: "/train-at-home.svg/" }) {
      publicURL
    }

    heroImageMitchAlex: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-1920x874.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImage: file(absolutePath: { regex: "/mitch-squat-light-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    handstandImage: file(absolutePath: { regex: "/mitch-handstand-dark-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1200, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    deadliftImage: file(absolutePath: { regex: "/mitch-deadlift-dark-1438x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1200, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    atHomeImage: file(absolutePath: { regex: "/Home.svg/" }) {
      publicURL
    }
    atGymImage: file(absolutePath: { regex: "/Dumbbell.svg/" }) {
      publicURL
    }

    headshotAndrewHatfield: file(absolutePath: { regex: "/andrewhatfield-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotByronPaine: file(absolutePath: { regex: "/byron-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotCraigMacDonald: file(absolutePath: { regex: "/craig-macdonald-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotMichaelHanson: file(absolutePath: { regex: "/michael-hanson-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotDallasAllen: file(absolutePath: { regex: "/dallas-allen-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotJunyiZhou: file(absolutePath: { regex: "/junyi-zhou-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotMitchJobson: file(
      absolutePath: { regex: "/mitch-standing-arms-crossed-blue-20200506_152253-2-778x778.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 778, height: 778, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    socialImage: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-social-1200x628.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
