import styled from "styled-components"

export const Responsive2ColumnContainerElement = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.theme.spacings.medium};

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

export const Responsive2ColumnItemElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const NineItemGridElement = styled.div`
  display: grid;
  ${"" /* height: 100%; */}
  width: 100%;
  overflow: scroll;
  grid-template-rows: repeat(9, auto);
  grid-coloumn-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas:
    "one"
    "two"
    "three"
    "four"
    "five"
    "six"
    "seven"
    "eight"
    "nine";

  @media screen and (min-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      "one two"
      "three four"
      "five six"
      "seven eight"
      "nine nine";
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);

    grid-template-areas:
      "one four seven"
      "two five eight"
      "three six nine";
  }
`

export const FourItemGridElement = styled.div`
  display: grid;
  ${"" /* height: 100%; */}
  width: 100%;
  overflow: scroll;
  grid-coloumn-gap: 10px;

  ${"" /* grid-template-columns: 1fr; */}
  ${"" /* grid-template-rows: repeat(4, 1fr); */}
  grid-template-areas:
    "one"
    "two"
    "three"
    "four";

  @media screen and (min-width: 720px) {
    ${"" /* grid-template-columns: repeat(2, 1fr); */}
    ${"" /* grid-template-rows: repeat(2, 1fr); */}
    grid-template-areas:
      "one two"
      "three four";
  }

  @media screen and (min-width: 1024px) {
    ${"" /* grid-template-columns: repeat(2, 1fr); */}
    ${"" /* grid-template-rows: repeat(2, 1fr); */}

    grid-template-areas:
      "one two"
      "three four";
  }
`
