import styled from "styled-components"

export const BulletedIconWrapperElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const BulletedIconLeftElement = styled.div`
  display: flex;
  margin-right: 30px;
`

export const BulletedIconRightElement = styled.div`
  display: flex;
  flex-direction: column; 
  }
`
// export const BulletedIconIconElement = styled.div`
//   padding-left: 5px;
//   padding-right: 5px;
// `
