import React from "react"

import { IconContext } from "react-icons"

import {
  BulletedIconWrapperElement,
  BulletedIconLeftElement,
  BulletedIconRightElement,
  //   BulletedIconIconElement,
} from "../elements/BulletedIconElements"

export const BulletedIconWrapper = (props) => {
  return <BulletedIconWrapperElement {...props}>{props.children}</BulletedIconWrapperElement>
}

export const BulletedIconLeft = (props) => {
  return (
    <IconContext.Provider value={{ style: { fontSize: "50px", color: props.color } }}>
      <BulletedIconLeftElement {...props}>{props.children}</BulletedIconLeftElement>
    </IconContext.Provider>
  )
}

export const BulletedIconRight = (props) => {
  return <BulletedIconRightElement {...props}>{props.children}</BulletedIconRightElement>
}

// export const BulletedIconIcon = (props) => {
//   return (
//     <IconContext.Provider value={{ style: { fontSize: "50px", color: props.color } }}>
//       <BulletedIconIconElement {...props}>{props.children}</BulletedIconIconElement>
//     </IconContext.Provider>
//   )
// }
