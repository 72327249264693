import styled from "styled-components"

export const BlurbElement = styled.div`
  display: flex;
  flex-direction: column;
  ${'' /* box-sizing: border-box; */}
  ${"" /* height: 100%; */}
  ${"" /* grid-template-rows: auto auto;
  grid-template-columns: auto auto; */}

  ${"" /* grid-template-areas: "BlurbIcon BlurbBody"; */}
  ${"" /* grid-template-areas:
    "BlurbIcon BlurbHeading"
    "BlurbText BlurbText"; */}

  ${"" /* gap: 30px; */}
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
`

export const BlurbHeaderElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 5px;
`

export const BlurbIconElement = styled.div`
  grid-area: BlurbIcon;
  padding-left: 5px;
  padding-right: 5px;
`

export const BlurbHeadingElement = styled.div`
  ${"" /* font-size: 1.8rem;
  line-height: 1.8rem;
  font-weight: 600;
  font-family: "Roboto"; */}
  font-size: ${(props) => props.theme.spacings.medium};
  line-height: ${(props) => props.theme.spacings.medium};
  font-weight: ${(props) => props.theme.fonts.fontWeight600};
  font-family: ${(props) => props.theme.fonts.header1};
  padding-bottom: 10px;
  padding-left: 5px;
  margin-left: 5px;
`

export const BlurbTextElement = styled.div`
  ${"" /* font-size: 1.3rem;
  line-height: 1.4rem;
  font-weight: 400;
  font-family: "PT Sans"; */}
  font-size: ${(props) => props.theme.spacings.small};
  line-height: ${(props) => props.theme.spacings.medium};
  font-weight: ${(props) => props.theme.fonts.fontWeight400};
  font-family: ${(props) => props.theme.fonts.header4};
  ${'' /* display: flex; */}
  grid-area: BlurbText;
`
