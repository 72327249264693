import React from "react"

import {
  Responsive2ColumnContainerElement,
  Responsive2ColumnItemElement,
  NineItemGridElement,
  FourItemGridElement,
} from "../elements/Responsive2ColumnElement"

export const Responsive2ColumnContainer = (props) => {
  return (
    <Responsive2ColumnContainerElement {...props}>
      {props.children}
    </Responsive2ColumnContainerElement>
  )
}

// export const Responsive2ColumnItem = (props) => {
//   return <Responsive2ColumnItemElement {...props}>{props.children}</Responsive2ColumnItemElement>
// }

export const Responsive2ColumnItem = (props) => {
  return (
    <div className="flex flex-col" {...props}>
      {props.children}
    </div>
  )
}

export const NineItemGrid = (props) => {
  return <NineItemGridElement {...props}>{props.children}</NineItemGridElement>
}

export const FourItemGrid = (props) => {
  return <FourItemGridElement {...props}>{props.children}</FourItemGridElement>
}
